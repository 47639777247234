.files-wrapper {
  background-color: #383844;
}

.eb-filetree-action {
  display: none;
}

.eb-tree__body--active {
  background-color: rgba(255, 255, 255, 0.15);
}

.react-contextmenu {
  display: none;
}
