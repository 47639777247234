.App {
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
}
.pad-row {
  padding: 0.25rem 0.5rem;
}
.flex-item {
  flex: 0 0 auto;
  position: relative;
}
.flex-fill-item {
  flex: 1 1 auto;
  position: relative;
}
