.project-viewer {
  background-color: #2e2e3b;
  width: 100%;
  height: 100%;

  &__file-tree {
    background-color: #2e2e3b;

    .eb-resizable__content {
      > .eb-filetree-wrapper {
        background: #20202a;
        border: 0;
      }

      > .eb-filetree-wrapper
        > .react-contextmenu-wrapper
        > .eb-filetree
        > .eb-tree__children--is-visible {
        background-color: #20202a;
        margin-bottom: 1.5rem;
      }

      .eb-filetree > .eb-tree__children--is-visible {
        background-color: #2e2e3b;
      }

      .eb-tree__body--active {
        background-color: #46465a;
      }
    }
  }

  &__markdown {
    color: #c9cacc;
    background-color: #20202a;
    padding: 2.75rem 1.5rem;
    width: 100%;
    height: 100%;
    overflow: auto;

    a {
      color: #7979d3;
    }

    strong {
      color: inherit;
    }

    pre {
      border-radius: 2px;
      border: solid 1px #46465a;
      background-color: #2e2e3b;
    }

    code {
      border-radius: 2px;
      background-color: #46465a;
      color: #f9fafc;
      font-weight: normal;
    }
  }

  &__file-viewer {
    width: 100%;
    height: 100%;
    overflow: hidden;

    &--hide {
      // ex. { requiresFileViewer === 'markdown' && <Markdown .. /> } completely removes element on False, making transition slower
      display: none;
    }
  }

  &__shared-code-editor {
    &--hide {
      display: none;
    }
  }
}
